<template>
    <v-container fluid>
        <v-card>
            <v-card-text>
                <v-sheet height="85vh">
                    <v-calendar
                        v-model="value"
                        ref="calendar"
                        color="primary"
                        :weekdays="[1, 2, 3, 4, 5]"
                        type="week"
                        event-overlap-mode="stack"
                        :event-overlap-threshold="30"
                    >
                        <template v-slot:day-body="{ date, week }">
                            <div
                                class="v-current-time"
                                :class="{ first: date === week[0].date }"
                                :style="{ top: nowY }"
                            ></div>
                        </template>
                    </v-calendar>
                </v-sheet>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
export default {
    name: 'times',
    data: () => ({
        value: '',
        ready: false,
    }),
    computed: {
        cal() {
            return this.ready ? this.$refs.calendar : null;
        },
        nowY() {
            return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px';
        },
    },
    mounted() {
        this.ready = true;
        this.scrollToTime();
        this.updateTime();
    },
    methods: {
        getCurrentTime() {
            return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0;
        },
        scrollToTime() {
            const time = this.getCurrentTime();
            const first = Math.max(0, time - (time % 30) - 30);

            this.cal.scrollToTime(first);
        },
        updateTime() {
            setInterval(() => this.cal.updateTimes(), 60 * 1000);
        },
    },
};
</script>

<style lang="scss">
.v-current-time {
    height: 2px;
    background-color: #ea4335;
    position: absolute;
    left: -1px;
    right: 0;
    pointer-events: none;

    &.first::before {
        content: '';
        position: absolute;
        background-color: #ea4335;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-top: -5px;
        margin-left: -6.5px;
    }
}

.v-calendar-daily__scroll-area::-webkit-scrollbar {
    width: 15px;
}

.v-calendar-daily__scroll-area::-webkit-scrollbar-track {
    background: #ffffff;
    border-left: 0px solid #dadada;
}

.v-calendar-daily__scroll-area::-webkit-scrollbar-thumb {
    background: #cccccc;
    border: solid 4px #ffffff;
    border-radius: 7px;
}
</style>
